import React from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { StyledButton } from './StyledComponents';
import logoUrl from './assets/metropolis_logo.png'; // Make sure this path is correct

interface QuoteData {
  dealName: string;
  companyName: string;
  contactName: string;
  contactEmail: string;
  preparedBy: string;
  preparerEmail: string;
  productName: string;
  partNumber: string;
  quantity: number;
  unitPrice: number;
  discount: number;
  additionalYears: number;
  initialSupportPrice: number;
  annualSupportPrice: number;
  fiveYearPromotion: string;
  overviewText: string;
  requirements: string;
  productIncludes: string;
  supportBundlePricing: string;
  date: string;
  quoteNumber: string;
  validUntil: string;
}

interface QuoteGeneratorProps {
  quoteData: QuoteData;
}

const QuoteGenerator: React.FC<QuoteGeneratorProps> = ({ quoteData }) => {
  const generatePDF = () => {
    const doc = new jsPDF();

    // Add logo
    doc.addImage(logoUrl, 'PNG', 15, 15, 30, 30);

    // Set colors
    const primaryColor = '#FF6600';
    const secondaryColor = '#00A651';

    // Add content to the PDF
    doc.setFontSize(25);
    doc.setTextColor(primaryColor);
    doc.text('Metropolis Corp Quote', 50, 25);

    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.text(`Created Date: ${quoteData.date}`, 150, 20);
    doc.text(`Quote Number: ${quoteData.quoteNumber}`, 150, 27);
    doc.text(`Valid Until: ${quoteData.validUntil}`, 150, 34);

    // Client Information
    doc.setFontSize(16);
    doc.setTextColor(secondaryColor);
    doc.text('Client Information', 15, 55);
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.text(`Deal Name: ${quoteData.dealName}`, 15, 65);
    doc.text(`Company Name: ${quoteData.companyName}`, 15, 72);
    doc.text(`Contact Name: ${quoteData.contactName}`, 15, 79);
    doc.text(`Contact Email: ${quoteData.contactEmail}`, 15, 86);

    doc.text(`Prepared by: ${quoteData.preparedBy}`, 120, 65);
    doc.text(`Preparer's Email: ${quoteData.preparerEmail}`, 120, 72);

    // Product Details
    doc.setFontSize(16);
    doc.setTextColor(secondaryColor);
    doc.text('Product Details', 15, 100);
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);

    const productData = [
      ['Product & Description', 'Part Number', 'Quantity', 'Unit Price', 'Discount', 'Total'],
      [
        quoteData.productName,
        quoteData.partNumber,
        quoteData.quantity.toString(),
        `$${quoteData.unitPrice.toFixed(2)}`,
        `${quoteData.discount}%`,
        `$${(quoteData.quantity * quoteData.unitPrice * (1 - quoteData.discount / 100)).toFixed(2)}`
      ]
    ];

    (doc as any).autoTable({
      startY: 105,
      head: [productData[0]],
      body: [productData[1]],
      theme: 'grid',
      headStyles: { fillColor: [255, 102, 0] },
    });

    // Support and Additional Services
    doc.setFontSize(16);
    doc.setTextColor(secondaryColor);
    doc.text('Support and Additional Services', 15, doc.lastAutoTable.finalY + 15);
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.text(`Initial Support Price: $${quoteData.initialSupportPrice.toFixed(2)}`, 15, doc.lastAutoTable.finalY + 25);
    doc.text(`Annual Support Price: $${quoteData.annualSupportPrice.toFixed(2)}`, 15, doc.lastAutoTable.finalY + 32);
    doc.text(`Five-Year Promotion: ${quoteData.fiveYearPromotion}`, 15, doc.lastAutoTable.finalY + 39);
    doc.text(`Additional Years of Support: ${quoteData.additionalYears}`, 15, doc.lastAutoTable.finalY + 46);

    // General Information
    doc.setFontSize(16);
    doc.setTextColor(secondaryColor);
    doc.text('General Information', 15, doc.lastAutoTable.finalY + 60);
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.text('Overview:', 15, doc.lastAutoTable.finalY + 70);
    doc.setFontSize(10);
    doc.text(quoteData.overviewText, 15, doc.lastAutoTable.finalY + 77, { maxWidth: 180 });

    doc.setFontSize(12);
    doc.text('Requirements:', 15, doc.lastAutoTable.finalY + 97);
    doc.setFontSize(10);
    doc.text(quoteData.requirements, 15, doc.lastAutoTable.finalY + 104, { maxWidth: 180 });

    doc.setFontSize(12);
    doc.text('Product Includes:', 15, doc.lastAutoTable.finalY + 124);
    doc.setFontSize(10);
    doc.text(quoteData.productIncludes, 15, doc.lastAutoTable.finalY + 131, { maxWidth: 180 });

    doc.setFontSize(12);
    doc.text('Support Bundle Pricing:', 15, doc.lastAutoTable.finalY + 151);
    doc.setFontSize(10);
    doc.text(quoteData.supportBundlePricing, 15, doc.lastAutoTable.finalY + 158, { maxWidth: 180 });

    // Total
    const totalPrice = quoteData.quantity * quoteData.unitPrice * (1 - quoteData.discount / 100) +
      quoteData.initialSupportPrice + (quoteData.additionalYears * quoteData.annualSupportPrice);
    
    doc.setFontSize(16);
    doc.setTextColor(primaryColor);
    doc.text(`Grand Total: $${totalPrice.toFixed(2)}`, 15, 280);

    // Footer
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text('Terms and Conditions: [Your standard terms and conditions]', 15, 290);
    doc.text('For more information, visit: www.metropolis.com', 15, 295);

    // Save the PDF
    doc.save('Metropolis_Quote.pdf');
  };

  return (
    <StyledButton onClick={generatePDF}>Download Quote PDF</StyledButton>
  );
};

export default QuoteGenerator;