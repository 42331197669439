import React, { useEffect } from 'react';

// Add this declaration at the top of the file
declare global {
  interface Window {
    myChatWidget?: {
      load: (config: { assistantId: string; apiKey: string }) => void;
    };
  }
}

const EntraClean: React.FC = () => {
  useEffect(() => {
    // Load the AI assistant script
    const script = document.createElement('script');
    script.src = "https://agentivehub.com/production.bundle.min.js";
    script.async = true;
    script.onload = () => {
      if (window.myChatWidget && typeof window.myChatWidget.load === 'function') {
        window.myChatWidget.load({
          assistantId: '22166886-aa5c-48de-be64-f460653ff389',
          apiKey: '5e9fc760-d861-45eb-8095-72159f99cdf4',
        });
      }
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div dangerouslySetInnerHTML={{ __html: `
      <div class="naviagtion naviagtion-white fixed-top transition">
        <div class="container">
          <nav class="navbar navbar-expand-lg navbar-light p-0">
            <a class="navbar-brand p-0" href="https://www.metropolis.com">
              <img src="https://www.metropolis.com/ngimages/1/logo-dark.png" class="img-fluid" alt="Metropolis Corp Logo">
            </a>
          </nav>
        </div>
      </div>

      <section class="page-title page-title-overlay bg-cover overflow-hidden">
        <div class="container">
          <div class="row">
            <div class="col-lg-7">
              <h1 class="text-white position-relative">Tidy your Data<span class="watermark-sm">Welcome</span></h1>
              <p class="text-white pt-4 pb-4">Thanks for downloading EntraClean for Cisco UCM! Cisco customers transitioning to WebEx Calling, EntraClean will help them manage and clean up their extensions, devices, NUM plans, and phone numbers, optimizing your directory for smoother operations and helping you keep your voice network infrastructure nice and tidy!</p>
              <ul class="list-inline">
                <li class="list-inline-item mx-1 my-2"> <a href="#started" class="btn btn-primary" data-aos="zoom-in" data-aos-delay="10">Get Started</a> </li>
                <li class="list-inline-item mx-1 my-2"> <a href="#upgrade" class="btn btn-primary" data-aos="zoom-in" data-aos-delay="10">Add More Data</a> </li>
              </ul>
            </div>
            <div class="col-lg-3 ml-auto align-self-end">
              <p class="position-relative" aria-label="breadcrumb">
                <ol class="breadcrumb justify-content-lg-end bg-transparent mb-4 px-0">
                  <li class="breadcrumb-item text-white fw-bold" aria-current="page"><img src="https://www.metropolis.com/ngimages/entracleanx375.png" style="float:right" alt="screenshot of bubbles cleaning Entra Active Directory" /></li>
                </ol>
              </p>
            </div>
          </div>
        </div>
      </section>

      <!-- Integration section -->
      <section>
        <div class="bg-light py-2">
          <div class="row">
            <div class="col-12 text-center"> <br>
              <h3 class="section-title">EntraClean can also <b>Integrate</b> With:</h3>
            </div>
          </div>
        </div>
      </section>

      <!-- Integration logos -->
      <section class="bg-light py-2">
        <div class="container">
          <div class="row">
            <div class="col-12" data-aos="fade-up" data-aos-delay="200">
              <ul class="list-inline d-flex justify-content-between align-items-center">
                <li class="list-inline-item"><a href="#upgrade"><img class="img-fluid p-2" src="ngimages/PBX/samsung.png" alt="samsung Logo"></a></li>
                <li class="list-inline-item"><a href="#upgrade"><img class="img-fluid p-2" src="ngimages/PBX/ringcentral.png" alt="Ring Central Logo"></a></li>
                <li class="list-inline-item"><a href="#upgrade"><img class="img-fluid p-2" src="ngimages/PBX/zoom.png" alt="Zoom Reporting Logo"></a></li>
                <li class="list-inline-item"><a href="#upgrade"><img class="img-fluid p-2" src="ngimages/PBX/teams.png" alt="Microsoft Teams Logo"></a></li>
                <li class="list-inline-item"><a href="#upgrade"><img class="img-fluid p-2" src="ngimages/PBX/nice.png" alt="Blue NICE CxONE logo"></a></li>
                <li class="list-inline-item"><a href="#upgrade"><img class="img-fluid p-2" src="ngimages/PBX/genesys.png" alt="Genesys logo"></a></li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section class="bg-light py-2">
        <div class="container">
          <div class="row">
            <div class="col-12" data-aos="fade-up" data-aos-delay="200">
              <ul class="list-inline d-flex justify-content-between align-items-center">
                <li class="list-inline-item"><a href="#upgrade"><img class="img-fluid p-2" src="ngimages/PBX/nec.png" alt="NEC logo for call reporting"></a></li>
                <li class="list-inline-item"><a href="#upgrade"><img class="img-fluid p-2" src="ngimages/PBX/cisco.png" alt="cisco logo call reporting software"></a></li>
                <li class="list-inline-item"><a href="#upgrade"><img class="img-fluid p-2" src="ngimages/PBX/avaya.png" alt="avaya call reporting logo"></a></li>
                <li class="list-inline-item"><a href="#upgrade"><img class="img-fluid p-2" src="ngimages/PBX/mitel.png" alt="mitel call reporting logo"></a></li>
                <li class="list-inline-item"><a href="#upgrade"><img class="img-fluid p-2" src="ngimages/PBX/five9.png" alt="Five 9 reporting blue logo"></a></li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section class="section">
        <div class="container">
          <div class="row">
            <div class="col-12 text-center">
              <p class="subtitle" data-aos="fade-up">Let's Connect Your Data!</p>
              <h2 id="started" class="section-title" data-aos="fade-up" data-aos-delay="200">Getting Started</h2>
            </div>
            <div class="col-lg-6 col-sm-6 mb-6" data-aos="fade-up" data-aos-delay="200">
              <div class="card border-0 shadow rounded-xs pt-5">
                <div class="card-body">
                  <i class="fa fa-phone icon-lg icon-primary icon-bg-primary icon-bg-circle mb-3"></i>
                  <h4 class="mt-4 mb-3">Connect your Data</h4>
                  <p>Follow these steps to get started with EntraClean:</p>
                  <ol>
                    <li><a href="https://metropolis.com/how-to/cdr-export-from-cisco-cucm.php" target="_blank">Export your Cisco UCM Data</a></li>
                    <li>Login and upload it to our <a href="https://metropolis0.sharepoint.com/:f:/s/guests/EtCag_Mjlc1LiLC7xS8CZIABkmDNsfPP98tkYmOJu3ztYA?e=P5hmTg" target="blank">Sharepoint Link</a></li>
                    <li>Review your DID's, extensions, and optimize!</li>
                  </ol>
                  <a href="https://metropolis.com/how-to/cdr-export-from-cisco-cucm.php" target="_blank" class="btn btn-outline-primary text-black ml-3">Export CDR</a>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-sm-6 mb-4" data-aos="fade-up" data-aos-delay="200">
              <div class="card border-0 shadow rounded-xs pt-5">
                <div class="card-body">
                  <i class="fa fa-users icon-lg icon-yellow icon-bg-yellow icon-bg-circle mb-3"></i>
                  <h4 class="mt-4 mb-3">AI Assistant</h4>
                  <p>Need help? Use our integrated AI Assistant to guide you through the setup process for EntraClean for Cisco UCM. Just click the chat icon in the bottom right corner to get started!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <a id="upgrade"></a>
      <section id="upgrade" class="section pb-0">
      </section>
      
      <section class="bg-triangles bg-gradient-primary1 overflow-hidden">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 text-center text-lg-left">
              <div class="section">
                <h2 class="section-title glowing-text" data-aos="fade-up">
                  Need more? Get an Upgrade
                </h2>
                <p class="text-white mb-4" data-aos="fade-up" data-aos-delay="200">Looking to pull reports on more communication platforms? Request a license upgrade to your EntraClean system and pull data from multiple UC sources with Expo XT UC Analytics.</p>
                <p class="text-white">Gain robust insights into every chat, meeting, and voice call, with AI-assisted analysis of your entire communication infrastructure.</p>
              </div>
            </div>
            <div class="col-lg-6 align-self-end" data-aos="fade-left" data-aos-delay="600">
              <div class="col-12 text-center">
                <p class="subtitle"></p>
                <h2 class="section-title glowing-text"></h2>
              </div>
            </div>
          </div>
        </div>
      </section>
    `}} />
  );
};

export default EntraClean;