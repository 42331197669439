import React from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink } from 'react-router-dom';
import BattleCardGallery from './BattleCardGallery';
import QuoteForm from './QuoteForm';
import QuoteGenerator from './QuoteGenerator';
import EntraClean from './entraclean/EntraClean';
import './App.css';

function App() {
  const [quoteData, setQuoteData] = React.useState(null);

  const handleQuoteSubmit = (values) => {
    const newQuoteData = {
      ...values,
      date: new Date().toLocaleDateString(),
      quoteNumber: `#${Math.floor(Math.random() * 1000000)}`,
      validUntil: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toLocaleDateString(),
    };
    setQuoteData(newQuoteData);
  };

  return (
    <Router>
      <div className="App">
        <nav>
          <ul>
            <li>
              <NavLink to="/" className={({ isActive }) => (isActive ? 'active' : '')}>Battle Card Gallery</NavLink>
            </li>
            <li>
              <NavLink to="/generate-quote" className={({ isActive }) => (isActive ? 'active' : '')}>Generate Quote</NavLink>
            </li>
            <li>
              <NavLink to="/entraclean" className={({ isActive }) => (isActive ? 'active' : '')}>EntraClean</NavLink>
            </li>
          </ul>
        </nav>

        <Routes>
          <Route path="/" element={<BattleCardGallery />} />
          <Route path="/generate-quote" element={
            <>
              <QuoteForm onSubmit={handleQuoteSubmit} />
              {quoteData && <QuoteGenerator quoteData={quoteData} />}
            </>
          } />
          <Route path="/entraclean" element={<EntraClean />} />
          <Route path="*" element={<NotFound />} /> {/* 404 page handling */}
        </Routes>
      </div>
    </Router>
  );
}

function NotFound() {
  return <h2>404: Page Not Found</h2>;
}

export default App;