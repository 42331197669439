import styled from 'styled-components';
import { Form, Field, ErrorMessage } from 'formik';

export const StyledForm = styled(Form)`
  display: grid;
  gap: 1rem;
  max-width: 500px;
  margin: 0 auto;
`;

export const StyledField = styled(Field)`
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

export const StyledErrorMessage = styled(ErrorMessage)`
  color: red;
  font-size: 0.8rem;
`;

export const StyledButton = styled.button`
  background-color: #FF6600;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #e65c00;
  }
`;

export const StyledHeading = styled.h2`
  color: #FF6600;
  text-align: center;
`;

export const StyledSection = styled.section`
  background-color: #f9f9f9;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
`;