import React, { useState } from 'react';
import { Search, Filter, Phone, BarChart, VideoIcon, Headphones, ChevronDown, ChevronUp, ExternalLink } from 'lucide-react';
import './BattleCardGallery.css';
import avayaIcon from './assets/avaya.svg';
import ciscoIcon from './assets/cisco.svg';
import necIcon from './assets/nec.svg';
import niceIcon from './assets/nice.svg';
import ringcentralIcon from './assets/ringcentral.svg';
import samsungIcon from './assets/samsung.svg';
import zoomIcon from './assets/zoom.svg';
import teamsIcon from './assets/teams.svg';
import webexIcon from './assets/webex.svg';

// Define types for our data structure
interface PricingTier {
  [key: string]: string;
}

interface Resource {
  datasheet?: string;
  demo?: string;
  discoveryQuestions?: string;
}

interface GalleryItem {
  id: number;
  name: string;
  description: string;
  category: string;
  tags: string[];
  pricing: PricingTier;
  sectors: string[];
  benefits: string[];
  resources: Resource;
  licensing: string;
  customerType: string[];
  deploymentType: string[];
  pbxIntegration: string[];
  industry: string[];
  dataAvailability: string[];
}

const categoryIcons: { [key: string]: JSX.Element } = {
  "Premise-Based UC Analytics": <BarChart size={24} />,
  "Real-Time Dashboards": <BarChart size={24} />,
  "UC Analytics": <VideoIcon size={24} />,
  "Call Center Management": <Headphones size={24} />,
  "Call Reporting": <Phone size={24} />,
  "Hotel Call Accounting": <Phone size={24} />
};

const pbxIntegrationIcons: { [key: string]: string } = {
  'Avaya': avayaIcon,
  'Cisco UCM': ciscoIcon,
  'NEC': necIcon,
  'NICE CXone': niceIcon,
  'RingCentral': ringcentralIcon,
  'Samsung': samsungIcon,
  'Teams': teamsIcon,
  'Zoom': zoomIcon,
  'Webex': webexIcon
};

const galleryData: GalleryItem[] = [
  {
    id: 1,
    name: "XT2",
    description: "Consolidated UC Analytics software solution designed for multi-site and cross-platform insights, providing comprehensive call accounting, performance and usage tracking, CDR analysis, 911 notifications, and toll fraud alerts.",
    category: "Premise-Based UC Analytics",
    tags: ["Call Accounting", "Performance & Usage", "CDR Analysis", "911 Notifications", "Toll Fraud Alerts"],
    pricing: {
      "250 Ext": "$X",
      "500 Ext": "$Y",
      "750 Ext": "$Z",
      "751+": "Contact for enterprise pricing promotions"
    },
    sectors: ["Enterprise", "Government", "Healthcare", "Education", "Legal", "Retail"],
    benefits: [
      "40% Margin Off Software & Initial Support Sales",
      "Money-Back Guarantee",
      "NO Installation Fee",
      "NO Training Fee",
      "Live 24/7 Technical Support"
    ],
    resources: {
      datasheet: "https://www.metropolis.com/lib/XT2_datasheet.pdf",
      demo: "https://scheduler.zoom.us/cdrguru"
    },
    licensing: "Perpetual",
    customerType: ["End User"],
    deploymentType: ["Premise"],
    pbxIntegration: ["Cisco UCM", "Avaya", "Mitel", "NEC", "Samsung"],
    industry: ["General Business", "SMB", "Enterprise"],
    dataAvailability: ["Historical"]
  },
  {
    id: 2,
    name: "QLive",
    description: "Real-time dashboard and reporting solution for Cisco Call Manager environments, offering live visibility, customizable dashboards, KPI alerts, historical reporting, and agent performance tracking to improve customer service.",
    category: "Real-Time Dashboards",
    tags: ["Live Visibility", "Customizable Dashboards", "KPI Alerts", "Historical Reporting", "Agent Performance"],
    pricing: {
      "5 to 24 Users": "$A per user",
      "25 to 99 Users": "$B per user",
      "100 to 249 Users": "$C per user",
      "250+": "Contact for pricing promotions"
    },
    sectors: ["General Business", "SMB", "Enterprise", "Government"],
    benefits: [
      "Win Price Sensitive Deals",
      "40% Margin Off Software & Initial Support",
      "60-Day Money-Back Guarantee",
      "Remote Installation and Training Included",
      "Live 24/7 Technical Support",
      "Support Promotions Available"
    ],
    resources: {
      demo: "https://scheduler.zoom.us/cdrguru",
      discoveryQuestions: "https://docs.google.com/presentation/d/1Gndi2qmeibXvJZx80wNu_pRPwmoSriWqvn4yKTuusMc/edit?usp=sharing"
    },
    licensing: "Perpetual",
    customerType: ["Reseller", "End User"],
    deploymentType: ["Premise"],
    pbxIntegration: ["Cisco UCM", "Cisco UCCX", "Avaya"],
    industry: ["General Business", "SMB", "Enterprise", "Government"],
    dataAvailability: ["Streaming", "Periodic Refresh"]
  },
  {
    id: 3,
    name: "Expo XT",
    description: "Advanced communication analytics solution designed to optimize and streamline communication workflows, featuring AI analytics, custom reports, real-time alerts, Power BI integration, and multi-platform support.",
    category: "UC Analytics",
    tags: ["AI Analytics", "Custom Reports", "Real-Time Alerts", "Power BI Integration", "Multi-Platform Support"],
    pricing: {
      "50 Users": "$P",
      "100 Users": "$Q",
      "250 Users": "$R",
      "251+": "Contact for pricing promotions"
    },
    sectors: ["Enterprise", "Healthcare", "Finance", "Nonprofits", "Government", "Retail", "Manufacturing", "Education"],
    benefits: [
      "Advanced Communication Analytics",
      "Customization and Scalability",
      "Security and Compliance",
      "Integration and Interoperability",
      "Support and Training"
    ],
    resources: {
      datasheet: "https://www.metropolis.com/lib/ExpoXT-FeatureMatrix.pdf",
      demo: "https://youtu.be/atfiAkQLAQk"
    },
    licensing: "Subscription",
    customerType: ["End User"],
    deploymentType: ["Premise", "Cloud", "Hybrid"],
    pbxIntegration: ["Cisco UCM", "Webex", "Teams", "Zoom", "Avaya", "NEC", "RingCentral", "Mitel", "Samsung"],
    industry: ["Healthcare", "Finance", "Government", "Manufacturing", "Education"],
    dataAvailability: ["Streaming", "Historical"]
  },
  {
    id: 4,
    name: "QCloud",
    description: "Advanced call center management solution leveraging AI-powered analytics and predictive capabilities, providing improved operational efficiency, enhanced customer experience, and data-driven decision making.",
    category: "Call Center Management",
    tags: ["AI Analytics", "Predictive Analytics", "Sentiment Analysis", "Power BI Integration"],
    pricing: {
      "Up to 5 Agents": "$U",
      "Up to 10 Agents": "$V",
      "Up to 25 Agents": "$W",
      "26+": "Contact for pricing promotions"
    },
    sectors: ["Retail", "Finance", "Healthcare", "Government", "Manufacturing", "Education", "General Business", "Hospitality"],
    benefits: [
      "Improved operational efficiency",
      "Enhanced customer experience",
      "Data-driven decision making"
    ],
    resources: {
      datasheet: "https://www.metropolis.com/qcloud-specs",
      demo: "https://www.metropolis.com/qcloud-demo"
    },
    licensing: "Subscription",
    customerType: ["Reseller", "End User"],
    deploymentType: ["Cloud"],
    pbxIntegration: ["Teams", "Mitel"],
    industry: ["Retail", "Finance", "Healthcare", "Government", "Manufacturing", "Education", "General Business"],
    dataAvailability: ["Streaming", "Periodic Refresh"]
  },
  {
    id: 5,
    name: "OfficeWatch SL",
    description: "On-premise call reporting solution providing detailed call detail record (CDR) and station message detail record (SMDR) reporting, with local deployment, report automation, comprehensive analytics, and wide compatibility.",
    category: "Call Reporting",
    tags: ["Local Deployment", "Report Automation", "Comprehensive Analytics", "Wide Compatibility"],
    pricing: {
      "Up to 50 Ext": "Call for Pricing",
      "Up to 100 Ext": "Call for Pricing",
      "Up to 250 Ext": "Call for Pricing",
      "251+": "Contact for pricing promotions"
    },
    sectors: ["SMB", "Retail", "Finance", "Healthcare", "Government", "Manufacturing", "Education", "General Business"],
    benefits: [
      "Local Deployment ensures full data control",
      "Automated reporting for easy management",
      "Comprehensive analytics across multiple locations",
      "12 months of support included",
      "Compatible with most on-prem PBX systems"
    ],
    resources: {
      datasheet: "https://www.metropolis.com/call-reporting.php",
      demo: "https://www.youtube.com/watch?v=JNDHEU3wkyg"
    },
    licensing: "Perpetual",
    customerType: ["Reseller", "End User"],
    deploymentType: ["Premise"],
    pbxIntegration: ["Cisco UCM", "Avaya", "Mitel", "NEC", "Samsung"],
    industry: ["SMB", "Retail", "Finance", "Healthcare", "Government", "Manufacturing", "Education", "General Business"],
    dataAvailability: ["Historical", "Periodic Refresh"]
  },
  {
    id: 6,
    name: "ProfitWatch SL",
    description: "Hotel call accounting solution designed to monitor and optimize telecom expenses, offering cost management, personalized billing, emergency notifications, PMS integration, and comprehensive reporting.",
    category: "Hotel Call Accounting",
    tags: ["Cost Management", "Personalized Billing", "Emergency Notifications", "PMS Integration", "Comprehensive Reporting"],
    pricing: {
      "Up to 150 Extensions": "Call for Pricing",
      "Up to 250 Extensions": "Call for Pricing",
      "Up to 500 Extensions": "Call for Pricing",
      "501+": "Contact for pricing promotions"
    },
    sectors: ["Hospitality"],
    benefits: [
      "Tracks and manages call costs effectively",
      "Customizable billing options for guests",
      "Includes 911 alarms and alerts via synthesized voice, text messages, emails, or pager notifications",
      "Seamlessly integrates with Property Management Systems (PMS) used in hotels",
      "Provides detailed call reporting and analytics"
    ],
    resources: {
      datasheet: "https://www.metropolis.com/call-reporting.php",
      demo: "https://www.youtube.com/watch?v=JNDHEU3wkyg"
    },
    licensing: "Perpetual",
    customerType: ["End User"],
    deploymentType: ["Premise"],
    pbxIntegration: ["Cisco UCM", "Avaya", "Mitel", "NEC", "Samsung"],
    industry: ["Hospitality"],
    dataAvailability: ["Historical"]
  }
];

interface GalleryCardProps {
  item: GalleryItem;
  onCompare: (id: number) => void;
  isCompared: boolean;
}

const GalleryCard: React.FC<GalleryCardProps> = ({ item, onCompare, isCompared }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="product-card gallery-item">
      <div className="gallery-item-header">
        <div className="gallery-item-title">
          {categoryIcons[item.category] || <Phone size={24} />}
          <span className="gallery-item-name">{item.name}</span>
        </div>
        <span className="gallery-item-category">{item.category}</span>
      </div>
      <p className="gallery-item-description">{item.description}</p>
      <div className="gallery-item-pricing">
        <h4>Pricing Tiers:</h4>
        <ul>
          {Object.entries(item.pricing).map(([tier, price]) => (
            <li key={tier}>{tier}: {price}</li>
          ))}
        </ul>
      </div>
      <div className="gallery-item-tags">
        {item.tags.map((tag, index) => (
          <span key={index} className="tag">{tag}</span>
        ))}
      </div>
      <button 
        type="button"
        onClick={() => setExpanded(!expanded)} 
        className="expand-button button"
      >
        {expanded ? <ChevronUp size={16} /> : <ChevronDown size={16} />} 
        <span>{expanded ? 'Less Details' : 'More Details'}</span>
      </button>
      {expanded && (
        <div className="expanded-content">
          <h4>Key Sectors:</h4>
          <ul>
            {item.sectors.map((sector, index) => (
              <li key={index}>{sector}</li>
            ))}
          </ul>
          <h4>Benefits:</h4>
          <ul>
            {item.benefits.map((benefit, index) => (
              <li key={index}>{benefit}</li>
            ))}
          </ul>
          <h4>Additional Information:</h4>
          <ul>
            <li>Licensing: {item.licensing}</li>
            <li>Customer Type: {item.customerType.join(", ")}</li>
            <li>Deployment Type: {item.deploymentType.join(", ")}</li>
            <li>PBX Integration: {item.pbxIntegration.join(", ")}</li>
            <li>Industry: {item.industry.join(", ")}</li>
            <li>Data Availability: {item.dataAvailability.join(", ")}</li>
          </ul>
          <div className="resources">
            {item.resources.datasheet && (
              <a 
                href={item.resources.datasheet} 
                target="_blank" 
                rel="noopener noreferrer"
                className="resource-link datasheet"
              >
                Datasheet <ExternalLink size={14} />
              </a>
            )}
            {item.resources.demo && (
              <a 
                href={item.resources.demo} 
                target="_blank" 
                rel="noopener noreferrer"
                className="resource-link demo"
              >
                Request Demo <ExternalLink size={14} />
              </a>
            )}
          </div>
        </div>
      )}
      <button 
        type="button"
        onClick={() => onCompare(item.id)} 
        className={`compare-button button ${isCompared ? 'compared' : ''}`}
      >
        {isCompared ? 'Remove from Comparison' : 'Add to Comparison'}
      </button>
    </div>
  );
};

interface ComparisonViewProps {
  comparedItems: GalleryItem[];
  onClose: () => void;
}

const ComparisonView: React.FC<ComparisonViewProps> = ({ comparedItems, onClose }) => {
  return (
    <div className="comparison-view">
      <div className="comparison-content">
        <h2 className="section-heading">Product Comparison</h2>
        <div className="comparison-grid">
          {comparedItems.map(item => (
            <div key={item.id} className="comparison-item product-card">
              <h3>{item.name}</h3>
              <p>{item.description}</p>
              <h4>Key Features:</h4>
              <ul>
                {item.tags.map((tag, index) => (
                  <li key={index}>{tag}</li>
                ))}
              </ul>
              <h4>Pricing:</h4>
              <ul>
                {Object.entries(item.pricing).map(([tier, price]) => (
                  <li key={tier}>{tier}: {price}</li>
                ))}
              </ul>
              <h4>Additional Information:</h4>
              <ul>
                <li>Licensing: {item.licensing}</li>
                <li>Customer Type: {item.customerType.join(", ")}</li>
                <li>Deployment Type: {item.deploymentType.join(", ")}</li>
                <li>PBX Integration: {item.pbxIntegration.join(", ")}</li>
                <li>Industry: {item.industry.join(", ")}</li>
                <li>Data Availability: {item.dataAvailability.join(", ")}</li>
              </ul>
            </div>
          ))}
        </div>
        <button type="button" onClick={onClose} className="close-comparison button">
          Close Comparison
        </button>
      </div>
    </div>
  );
};

const BattleCardGallery: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [selectedPBXIntegration, setSelectedPBXIntegration] = useState<string[]>([]);
  const [comparedItems, setComparedItems] = useState<GalleryItem[]>([]);
  const [isComparisonViewOpen, setIsComparisonViewOpen] = useState(false);

  const filteredItems = galleryData.filter(item => {
    const matchesSearch = searchTerm === '' || item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                          item.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
                          item.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
                          item.tags.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase())) ||
                          item.sectors.some(sector => sector.toLowerCase().includes(searchTerm.toLowerCase())) ||
                          item.licensing.toLowerCase().includes(searchTerm.toLowerCase()) ||
                          item.customerType.some(type => type.toLowerCase().includes(searchTerm.toLowerCase())) ||
                          item.deploymentType.some(type => type.toLowerCase().includes(searchTerm.toLowerCase())) ||
                          item.pbxIntegration.some(pbx => pbx.toLowerCase().includes(searchTerm.toLowerCase())) ||
                          item.industry.some(ind => ind.toLowerCase().includes(searchTerm.toLowerCase())) ||
                          item.dataAvailability.some(data => data.toLowerCase().includes(searchTerm.toLowerCase()));
    
    const matchesCategory = selectedCategory === 'All' || item.category === selectedCategory;
    const matchesPBXIntegration = selectedPBXIntegration.length === 0 || selectedPBXIntegration.some(pbx => item.pbxIntegration.includes(pbx));

    return matchesSearch && matchesCategory && matchesPBXIntegration;
  });

  const allCategories = Array.from(new Set(galleryData.map(item => item.category)));
  const allPBXIntegrations = Array.from(new Set(galleryData.flatMap(item => item.pbxIntegration)));

  const handleCompare = (itemId: number) => {
    setComparedItems(prev => {
      const isAlreadyCompared = prev.some(item => item.id === itemId);
      if (isAlreadyCompared) {
        return prev.filter(item => item.id !== itemId);
      } else {
        const itemToAdd = galleryData.find(item => item.id === itemId);
        return itemToAdd ? [...prev, itemToAdd] : prev;
      }
    });
  };

  return (
    <div className="battle-card-gallery">
      <div className="header">
        <img src="/banner.png" alt="Build Your Own Battle Card" className="banner-image" />
      </div>
      
      <div className="search-filter filters">
        <div className="search-input">
          <input
            type="text"
            placeholder="Search products, PBX, Customer Type, Deployment Type, Licensing and Data Availability, Industry"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Search className="search-icon" size={20} />
        </div>
        
        <div className="category-filter">
          <label htmlFor="category-select">Select Category:</label>
          <select
            id="category-select"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            <option value="All">All Categories</option>
            {allCategories.map(category => (
              <option key={category} value={category}>{category}</option>
            ))}
          </select>
          <Filter className="filter-icon" size={20} />
        </div>
      </div>

      <div className="filter-section">
        <h3 className="section-heading">Filter by PBX Integration:</h3>
        <div className="filter-buttons">
          {allPBXIntegrations.map(pbx => (
            <button
              key={pbx}
              type="button"
              className={`filter-button ${selectedPBXIntegration.includes(pbx) ? 'selected' : ''}`}
              onClick={() => setSelectedPBXIntegration(prev =>
                prev.includes(pbx) ? prev.filter(p => p !== pbx) : [...prev, pbx]
              )}
            >
              <img src={pbxIntegrationIcons[pbx]} alt={`${pbx} icon`} className="pbx-icon" />
            </button>
          ))}
        </div>
      </div>

      <div className="gallery-grid">
        {filteredItems.map((item) => (
          <GalleryCard 
            key={item.id} 
            item={item} 
            onCompare={handleCompare}
            isCompared={comparedItems.some(comparedItem => comparedItem.id === item.id)}
          />
        ))}
      </div>
      
      {filteredItems.length === 0 && (
        <p className="no-results">No products found. Try adjusting your search or filters.</p>
      )}

      {comparedItems.length > 0 && (
        <button 
          type="button"
          onClick={() => setIsComparisonViewOpen(true)}
          className="compare-floating-button button"
        >
          Compare ({comparedItems.length})
        </button>
      )}

      {isComparisonViewOpen && (
        <ComparisonView 
          comparedItems={comparedItems} 
          onClose={() => setIsComparisonViewOpen(false)} 
        />
      )}

      <div className="iframe-container">
        <iframe
          title="Chatbot"
          src="https://www.chatbase.co/chatbot-iframe/GUdyaRJ_RUk3aMfjMHJzk"
          className="chatbot-iframe"
        ></iframe>
      </div>
    </div>
  );
};

export default BattleCardGallery;
