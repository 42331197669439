import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';

// Styled components
const StyledSection = styled.section`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const StyledHeading = styled.h2`
  color: #FF6600;
  text-align: center;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FormSection = styled.div`
  display: flex;
  gap: 20px;
`;

const Column = styled.div`
  flex: 1;
`;

const StyledField = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const StyledErrorMessage = styled.div`
  color: red;
  font-size: 0.8em;
  margin-top: 5px;
`;

const StyledButton = styled.button`
  background-color: #FF6600;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;

  &:hover {
    background-color: #e65c00;
  }
`;

// Form values interface
interface QuoteFormValues {
  dealName: string;
  companyName: string;
  contactName: string;
  contactEmail: string;
  preparedBy: string;
  preparerEmail: string;
  productName: string;
  partNumber: string;
  quantity: number;
  unitPrice: number;
  discount: number;
  additionalYears: number;
  initialSupportPrice: number;
  annualSupportPrice: number;
  fiveYearPromotion: string;
  overviewText: string;
  requirements: string;
  productIncludes: string;
  supportBundlePricing: string;
}

interface QuoteFormProps {
  onSubmit: (values: QuoteFormValues, formikHelpers: FormikHelpers<QuoteFormValues>) => void | Promise<any>;
}

const QuoteForm: React.FC<QuoteFormProps> = ({ onSubmit }) => {
  const initialValues: QuoteFormValues = {
    dealName: '',
    companyName: '',
    contactName: '',
    contactEmail: '',
    preparedBy: '',
    preparerEmail: '',
    productName: '',
    partNumber: '',
    quantity: 1,
    unitPrice: 0,
    discount: 0,
    additionalYears: 0,
    initialSupportPrice: 0,
    annualSupportPrice: 0,
    fiveYearPromotion: '',
    overviewText: '',
    requirements: '',
    productIncludes: '',
    supportBundlePricing: ''
  };

  const validationSchema = Yup.object({
    dealName: Yup.string().required('Required'),
    companyName: Yup.string().required('Required'),
    contactName: Yup.string().required('Required'),
    contactEmail: Yup.string().email('Invalid email address').required('Required'),
    preparedBy: Yup.string().required('Required'),
    preparerEmail: Yup.string().email('Invalid email address').required('Required'),
    productName: Yup.string().required('Required'),
    partNumber: Yup.string().required('Required'),
    quantity: Yup.number().min(1, 'Must be at least 1').required('Required'),
    unitPrice: Yup.number().min(0, 'Must be positive').required('Required'),
    discount: Yup.number().min(0, 'Must be positive').max(100, 'Cannot exceed 100%'),
    additionalYears: Yup.number().min(0, 'Must be positive'),
    initialSupportPrice: Yup.number().min(0, 'Must be positive'),
    annualSupportPrice: Yup.number().min(0, 'Must be positive'),
    fiveYearPromotion: Yup.string(),
    overviewText: Yup.string(),
    requirements: Yup.string(),
    productIncludes: Yup.string(),
    supportBundlePricing: Yup.string()
  });

  return (
    <StyledSection>
      <StyledHeading>Generate Quote</StyledHeading>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
          <StyledForm onSubmit={handleSubmit}>
            <FormSection>
              <Column>
                <StyledField
                  name="dealName"
                  placeholder="Deal Name (e.g., Client Name - User Count)"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.dealName}
                />
                {touched.dealName && errors.dealName && (
                  <StyledErrorMessage>{errors.dealName}</StyledErrorMessage>
                )}

                <StyledField
                  name="companyName"
                  placeholder="Company Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.companyName}
                />
                {touched.companyName && errors.companyName && (
                  <StyledErrorMessage>{errors.companyName}</StyledErrorMessage>
                )}

                <StyledField
                  name="contactName"
                  placeholder="Contact Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.contactName}
                />
                {touched.contactName && errors.contactName && (
                  <StyledErrorMessage>{errors.contactName}</StyledErrorMessage>
                )}

                <StyledField
                  name="contactEmail"
                  placeholder="Contact Email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.contactEmail}
                />
                {touched.contactEmail && errors.contactEmail && (
                  <StyledErrorMessage>{errors.contactEmail}</StyledErrorMessage>
                )}
              </Column>

              <Column>
                <StyledField
                  name="preparedBy"
                  placeholder="Prepared by"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.preparedBy}
                />
                {touched.preparedBy && errors.preparedBy && (
                  <StyledErrorMessage>{errors.preparedBy}</StyledErrorMessage>
                )}

                <StyledField
                  name="preparerEmail"
                  placeholder="Preparer's Email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.preparerEmail}
                />
                {touched.preparerEmail && errors.preparerEmail && (
                  <StyledErrorMessage>{errors.preparerEmail}</StyledErrorMessage>
                )}
              </Column>
            </FormSection>

            <FormSection>
              <Column>
                <StyledField
                  name="productName"
                  placeholder="Product & Description"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.productName}
                />
                {touched.productName && errors.productName && (
                  <StyledErrorMessage>{errors.productName}</StyledErrorMessage>
                )}

                <StyledField
                  name="partNumber"
                  placeholder="Part Number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.partNumber}
                />
                {touched.partNumber && errors.partNumber && (
                  <StyledErrorMessage>{errors.partNumber}</StyledErrorMessage>
                )}

                <StyledField
                  name="quantity"
                  type="number"
                  placeholder="Quantity"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.quantity}
                />
                {touched.quantity && errors.quantity && (
                  <StyledErrorMessage>{errors.quantity}</StyledErrorMessage>
                )}

                <StyledField
                  name="unitPrice"
                  type="number"
                  placeholder="Unit Price"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.unitPrice}
                />
                {touched.unitPrice && errors.unitPrice && (
                  <StyledErrorMessage>{errors.unitPrice}</StyledErrorMessage>
                )}

                <StyledField
                  name="discount"
                  type="number"
                  placeholder="Discount (%)"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.discount}
                />
                {touched.discount && errors.discount && (
                  <StyledErrorMessage>{errors.discount}</StyledErrorMessage>
                )}

                <StyledField
                  name="additionalYears"
                  type="number"
                  placeholder="Additional Years of Support"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.additionalYears}
                />
                {touched.additionalYears && errors.additionalYears && (
                  <StyledErrorMessage>{errors.additionalYears}</StyledErrorMessage>
                )}
              </Column>

              <Column>
                <StyledField
                  name="initialSupportPrice"
                  type="number"
                  placeholder="Initial Support Price"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.initialSupportPrice}
                />
                {touched.initialSupportPrice && errors.initialSupportPrice && (
                  <StyledErrorMessage>{errors.initialSupportPrice}</StyledErrorMessage>
                )}

                <StyledField
                  name="annualSupportPrice"
                  type="number"
                  placeholder="Annual Support Price"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.annualSupportPrice}
                />
                {touched.annualSupportPrice && errors.annualSupportPrice && (
                  <StyledErrorMessage>{errors.annualSupportPrice}</StyledErrorMessage>
                )}

                <StyledField
                  name="fiveYearPromotion"
                  placeholder="Five-Year Promotion"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.fiveYearPromotion}
                />
                {touched.fiveYearPromotion && errors.fiveYearPromotion && (
                  <StyledErrorMessage>{errors.fiveYearPromotion}</StyledErrorMessage>
                )}
              </Column>
            </FormSection>

            <FormSection>
              <Column>
                <StyledTextArea
                  name="overviewText"
                  placeholder="Overview Text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.overviewText}
                />
                {touched.overviewText && errors.overviewText && (
                  <StyledErrorMessage>{errors.overviewText}</StyledErrorMessage>
                )}

                <StyledTextArea
                  name="requirements"
                  placeholder="Requirements"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.requirements}
                />
                {touched.requirements && errors.requirements && (
                  <StyledErrorMessage>{errors.requirements}</StyledErrorMessage>
                )}
              </Column>

              <Column>
                <StyledTextArea
                  name="productIncludes"
                  placeholder="Product Price Includes"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.productIncludes}
                />
                {touched.productIncludes && errors.productIncludes && (
                  <StyledErrorMessage>{errors.productIncludes}</StyledErrorMessage>
                )}

                <StyledTextArea
                  name="supportBundlePricing"
                  placeholder="Support Bundle Special Pricing"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.supportBundlePricing}
                />
                {touched.supportBundlePricing && errors.supportBundlePricing && (
                  <StyledErrorMessage>{errors.supportBundlePricing}</StyledErrorMessage>
                )}
              </Column>
            </FormSection>

            <StyledButton type="submit">Generate Quote</StyledButton>
          </StyledForm>
        )}
      </Formik>
    </StyledSection>
  );
};

export default QuoteForm;